/** The SideBarBtns class */
class SideBarBtns {
  /** Used to register the scroll event handler */
  Initialize() {
    /** When the user scrolls down 300px from the top of the document, show the buttons */
    window.addEventListener("scroll", this.ToggleButtons)
  }

  /** Displays/Hides the buttons */
  ToggleButtons() {
    /** If the current current scroll is 300px or more */
    if (
      document.body.scrollTop > 300 ||
      document.documentElement.scrollTop > 300
    ) {
      /** The scroll to top button is displayed */
      document.getElementById("toTop").classList.add("show")
    } else {
      /** The scroll to top button is hidden */
      document.getElementById("toTop").classList.remove("show")
    }
  }
}

exports.onRouteUpdate = () => {
  /** The SideBarBtns object is created */
  let sidebarbtns = new SideBarBtns()
  /** If the current page is an article page */
  if (document.getElementById("toTop")) {
    /** The SideBarBtns object is initialized */
    sidebarbtns.Initialize()
  }
}
