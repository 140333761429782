// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-d-pago-exitoso-jsx": () => import("./../../../src/pages/d/pago_exitoso.jsx" /* webpackChunkName: "component---src-pages-d-pago-exitoso-jsx" */),
  "component---src-pages-discografia-es-primavera-jsx": () => import("./../../../src/pages/discografia/es-primavera.jsx" /* webpackChunkName: "component---src-pages-discografia-es-primavera-jsx" */),
  "component---src-pages-discografia-frutos-de-la-memoria-jsx": () => import("./../../../src/pages/discografia/frutos-de-la-memoria.jsx" /* webpackChunkName: "component---src-pages-discografia-frutos-de-la-memoria-jsx" */),
  "component---src-pages-discografia-jsx": () => import("./../../../src/pages/discografia.jsx" /* webpackChunkName: "component---src-pages-discografia-jsx" */),
  "component---src-pages-discografia-la-palabra-jsx": () => import("./../../../src/pages/discografia/la-palabra.jsx" /* webpackChunkName: "component---src-pages-discografia-la-palabra-jsx" */),
  "component---src-pages-discografia-per-saecula-saeculorum-amen-jsx": () => import("./../../../src/pages/discografia/per-saecula-saeculorum-amen.jsx" /* webpackChunkName: "component---src-pages-discografia-per-saecula-saeculorum-amen-jsx" */),
  "component---src-pages-f-pago-exitoso-jsx": () => import("./../../../src/pages/f/pago_exitoso.jsx" /* webpackChunkName: "component---src-pages-f-pago-exitoso-jsx" */),
  "component---src-pages-fotos-jsx": () => import("./../../../src/pages/fotos.jsx" /* webpackChunkName: "component---src-pages-fotos-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-la-gotita-literaria-index-jsx": () => import("./../../../src/pages/la-gotita-literaria/index.jsx" /* webpackChunkName: "component---src-pages-la-gotita-literaria-index-jsx" */),
  "component---src-pages-libros-efemerides-abril-jsx": () => import("./../../../src/pages/libros/efemerides/abril.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-abril-jsx" */),
  "component---src-pages-libros-efemerides-agosto-jsx": () => import("./../../../src/pages/libros/efemerides/agosto.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-agosto-jsx" */),
  "component---src-pages-libros-efemerides-diciembre-jsx": () => import("./../../../src/pages/libros/efemerides/diciembre.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-diciembre-jsx" */),
  "component---src-pages-libros-efemerides-enero-jsx": () => import("./../../../src/pages/libros/efemerides/enero.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-enero-jsx" */),
  "component---src-pages-libros-efemerides-febrero-jsx": () => import("./../../../src/pages/libros/efemerides/febrero.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-febrero-jsx" */),
  "component---src-pages-libros-efemerides-jsx": () => import("./../../../src/pages/libros/efemerides.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-jsx" */),
  "component---src-pages-libros-efemerides-julio-jsx": () => import("./../../../src/pages/libros/efemerides/julio.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-julio-jsx" */),
  "component---src-pages-libros-efemerides-junio-jsx": () => import("./../../../src/pages/libros/efemerides/junio.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-junio-jsx" */),
  "component---src-pages-libros-efemerides-marzo-jsx": () => import("./../../../src/pages/libros/efemerides/marzo.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-marzo-jsx" */),
  "component---src-pages-libros-efemerides-mayo-jsx": () => import("./../../../src/pages/libros/efemerides/mayo.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-mayo-jsx" */),
  "component---src-pages-libros-efemerides-noviembre-jsx": () => import("./../../../src/pages/libros/efemerides/noviembre.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-noviembre-jsx" */),
  "component---src-pages-libros-efemerides-octubre-jsx": () => import("./../../../src/pages/libros/efemerides/octubre.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-octubre-jsx" */),
  "component---src-pages-libros-efemerides-septiembre-jsx": () => import("./../../../src/pages/libros/efemerides/septiembre.jsx" /* webpackChunkName: "component---src-pages-libros-efemerides-septiembre-jsx" */),
  "component---src-pages-libros-index-jsx": () => import("./../../../src/pages/libros/index.jsx" /* webpackChunkName: "component---src-pages-libros-index-jsx" */),
  "component---src-pages-notas-jsx": () => import("./../../../src/pages/notas.jsx" /* webpackChunkName: "component---src-pages-notas-jsx" */),
  "component---src-pages-poemas-abrazo-de-los-pueblos-jsx": () => import("./../../../src/pages/poemas/abrazo-de-los-pueblos.jsx" /* webpackChunkName: "component---src-pages-poemas-abrazo-de-los-pueblos-jsx" */),
  "component---src-pages-poemas-asombro-jsx": () => import("./../../../src/pages/poemas/asombro.jsx" /* webpackChunkName: "component---src-pages-poemas-asombro-jsx" */),
  "component---src-pages-poemas-cafayate-canto-jsx": () => import("./../../../src/pages/poemas/cafayate-canto.jsx" /* webpackChunkName: "component---src-pages-poemas-cafayate-canto-jsx" */),
  "component---src-pages-poemas-coplas-jsx": () => import("./../../../src/pages/poemas/coplas.jsx" /* webpackChunkName: "component---src-pages-poemas-coplas-jsx" */),
  "component---src-pages-poemas-es-primavera-jsx": () => import("./../../../src/pages/poemas/es-primavera.jsx" /* webpackChunkName: "component---src-pages-poemas-es-primavera-jsx" */),
  "component---src-pages-poemas-eterno-viaje-jsx": () => import("./../../../src/pages/poemas/eterno-viaje.jsx" /* webpackChunkName: "component---src-pages-poemas-eterno-viaje-jsx" */),
  "component---src-pages-poemas-frutos-de-la-memoria-jsx": () => import("./../../../src/pages/poemas/frutos-de-la-memoria.jsx" /* webpackChunkName: "component---src-pages-poemas-frutos-de-la-memoria-jsx" */),
  "component---src-pages-poemas-jsx": () => import("./../../../src/pages/poemas.jsx" /* webpackChunkName: "component---src-pages-poemas-jsx" */),
  "component---src-pages-poemas-memoria-del-origen-jsx": () => import("./../../../src/pages/poemas/memoria-del-origen.jsx" /* webpackChunkName: "component---src-pages-poemas-memoria-del-origen-jsx" */),
  "component---src-pages-poemas-paisaje-jsx": () => import("./../../../src/pages/poemas/paisaje.jsx" /* webpackChunkName: "component---src-pages-poemas-paisaje-jsx" */),
  "component---src-pages-reconocimientos-jsx": () => import("./../../../src/pages/reconocimientos.jsx" /* webpackChunkName: "component---src-pages-reconocimientos-jsx" */),
  "component---src-pages-revistas-jsx": () => import("./../../../src/pages/revistas.jsx" /* webpackChunkName: "component---src-pages-revistas-jsx" */),
  "component---src-pages-tokyo-1-jsx": () => import("./../../../src/pages/tokyo/1.jsx" /* webpackChunkName: "component---src-pages-tokyo-1-jsx" */),
  "component---src-pages-tokyo-2-jsx": () => import("./../../../src/pages/tokyo/2.jsx" /* webpackChunkName: "component---src-pages-tokyo-2-jsx" */),
  "component---src-pages-tokyo-3-jsx": () => import("./../../../src/pages/tokyo/3.jsx" /* webpackChunkName: "component---src-pages-tokyo-3-jsx" */),
  "component---src-pages-tokyo-4-jsx": () => import("./../../../src/pages/tokyo/4.jsx" /* webpackChunkName: "component---src-pages-tokyo-4-jsx" */),
  "component---src-pages-tokyo-index-jsx": () => import("./../../../src/pages/tokyo/index.jsx" /* webpackChunkName: "component---src-pages-tokyo-index-jsx" */),
  "component---src-pages-videos-jsx": () => import("./../../../src/pages/videos.jsx" /* webpackChunkName: "component---src-pages-videos-jsx" */),
  "component---src-pages-videos-otros-jsx": () => import("./../../../src/pages/videos/otros.jsx" /* webpackChunkName: "component---src-pages-videos-otros-jsx" */),
  "component---src-pages-videos-te-cuento-una-cosita-jsx": () => import("./../../../src/pages/videos/te-cuento-una-cosita.jsx" /* webpackChunkName: "component---src-pages-videos-te-cuento-una-cosita-jsx" */),
  "component---src-pages-yt-jsx": () => import("./../../../src/pages/yt.jsx" /* webpackChunkName: "component---src-pages-yt-jsx" */),
  "component---src-templates-libros-jsx": () => import("./../../../src/templates/libros.jsx" /* webpackChunkName: "component---src-templates-libros-jsx" */),
  "component---src-templates-notas-jsx": () => import("./../../../src/templates/notas.jsx" /* webpackChunkName: "component---src-templates-notas-jsx" */),
  "component---src-templates-revistas-jsx": () => import("./../../../src/templates/revistas.jsx" /* webpackChunkName: "component---src-templates-revistas-jsx" */)
}

