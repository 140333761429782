module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"es","openGraph":{"type":"website","locale":"es_AR","url":"https://eduardoceballos.com/","site_name":"Eduardo Ceballos"},"twitter":{"handle":"@PoetadeSalta","site":"@PoetadeSalta","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eduardo Ceballos","short_name":"Eduardo Ceballos","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e6a7fd8715996825b2d912753854d690"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
